import React from "react";

export default function Merch({ height, width, fillColor }) {
  return (
    <svg
      height={height}
      width={width}
      fill={fillColor}
      viewBox="0 0 512.002 512.002"
    >
      <g>
        <g>
          <path
            d="M509.654,148.868l-65.928-79.5c-24.947-30.086-61.672-47.34-100.755-47.34H169.032c-39.083,0-75.808,17.254-100.756,47.34
			l-65.928,79.5c-2.018,2.434-2.794,5.667-2.099,8.751c0.695,3.084,2.78,5.673,5.647,7.007l95.228,44.313v270.836
			c0,5.633,4.566,10.199,10.199,10.199H402.68c5.632,0,10.199-4.566,10.199-10.199V208.884l93.297-44.292
			c2.844-1.35,4.905-3.938,5.585-7.012C512.441,154.506,511.663,151.29,509.654,148.868z M310.546,42.427
			c-5.517,25.19-27.748,43.68-54.545,43.68c-26.797,0-49.03-18.489-54.546-43.68H310.546z M412.879,186.302v-9.899
			c0-5.633-4.567-10.199-10.199-10.199s-10.199,4.566-10.199,10.199v293.172H121.522V176.403c0-5.633-4.566-10.199-10.199-10.199
			s-10.199,4.566-10.199,10.199v10.036l-74.633-34.73l57.486-69.321c21.06-25.396,52.061-39.963,85.054-39.963h11.679l0.017-0.009
			c2.58,16.112,10.228,30.938,22.11,42.498c14.312,13.923,33.191,21.591,53.163,21.591c19.972,0,38.852-7.668,53.163-21.591
			c11.881-11.559,19.528-26.386,22.109-42.498l0.017,0.009h11.679c32.993,0,63.994,14.565,85.054,39.962l57.553,69.401
			L412.879,186.302z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M148.369,281.345c-5.633,0-10.199,4.566-10.199,10.199v154.188c0,5.633,4.566,10.199,10.199,10.199
			s10.199-4.566,10.199-10.199V291.544C158.568,285.911,154.002,281.345,148.369,281.345z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M148.369,221.272c-5.633,0-10.199,4.566-10.199,10.199v22.027c0,5.633,4.566,10.199,10.199,10.199
			s10.199-4.566,10.199-10.199v-22.027C158.568,225.838,154.002,221.272,148.369,221.272z"
          />
        </g>
      </g>
    </svg>
  );
}
