import React from 'react';

export default function Fingerprint({ height, width, fillColor }) {
	return (
		<svg
			height={height}
			width={width}
			viewBox="0 0 426.667 426.667"
			fill={fillColor}
		>
			<path d="M133.529,352.213c-12.907-23.147-19.733-51.52-19.733-82.24c0-51.627,44.693-93.653,99.52-93.653c54.933,0,99.52,42.027,99.52,93.653c0,5.867,4.8,10.667,10.667,10.667s10.667-4.8,10.667-10.667c0-63.467-54.187-114.987-120.853-114.987S92.462,206.613,92.462,269.973c0,34.347,7.787,66.453,22.507,92.693c14.4,25.707,24.427,37.547,42.88,56.213c2.133,2.133,4.8,3.2,7.573,3.2c2.667,0,5.44-0.96,7.36-3.2c4.267-4.053,4.267-10.88,0.107-15.04C156.462,387.307,147.289,376.747,133.529,352.213z" />
			<path d="M94.702,51.413c36.587-19.947,76.48-30.08,118.827-30.08c42.453,0,77.973,9.067,118.827,30.187c1.6,0.747,3.2,1.173,4.907,1.173h0c3.84,0,7.573-2.133,9.493-5.76c2.667-5.227,0.64-11.733-4.587-14.4C298.649,10.027,258.969,0,213.529,0c-45.867,0-89.28,10.987-129.067,32.64c-5.227,2.88-7.04,9.28-4.267,14.507C83.075,52.373,89.475,54.187,94.702,51.413z" />
			<path d="M212.569,103.04c-68.8,0-131.733,38.507-160.213,98.027c-9.707,20.16-14.613,43.413-14.613,69.013c0,28.8,5.12,56.32,15.573,84.373c2.133,5.547,8.213,8.32,13.76,6.293c5.547-2.133,8.32-8.213,6.293-13.76C60.675,312.96,59.182,286.72,59.182,270.08c0-22.4,4.16-42.56,12.48-59.84c24.96-52.267,80.32-85.973,141.013-85.973c85.227,0,154.56,65.387,154.56,145.813c0,22.933-19.947,41.493-44.373,41.493s-44.373-18.667-44.373-41.493c0-34.667-29.44-62.827-65.707-62.827s-65.707,28.16-65.707,62.827c0,42.133,16.427,81.707,46.187,111.36c23.04,22.933,45.227,35.52,79.253,44.8c0.853,0.32,1.813,0.427,2.773,0.427c4.693,0,8.96-3.093,10.24-7.787c1.6-5.653-1.813-11.52-7.467-13.12c-30.08-8.213-49.707-19.307-69.867-39.36c-25.707-25.6-39.893-59.84-39.893-96.213c0-22.933,19.947-41.493,44.373-41.493s44.373,18.667,44.373,41.493c0,34.667,29.547,62.827,65.707,62.827c36.16,0,65.707-28.16,65.707-62.827C388.462,178.027,309.529,103.04,212.569,103.04z" />
			<path d="M403.395,147.2c-21.227-29.653-48.107-52.907-80-69.333c-67.2-34.56-152.96-34.453-220.053,0.213c-32,16.533-58.987,40-80.107,69.867c-3.413,4.8-2.24,11.413,2.56,14.827c1.92,1.28,4.053,1.92,6.187,1.92c3.307,0,6.613-1.493,8.747-4.373c19.093-27.093,43.52-48.32,72.427-63.253c61.12-31.573,139.307-31.68,200.533-0.213c28.8,14.72,53.12,35.84,72.32,62.72c3.413,4.8,10.133,5.867,14.933,2.453C405.742,158.613,406.809,152,403.395,147.2z" />
			<path d="M340.569,359.253c-8.533,1.493-17.173,2.027-22.293,2.027c-21.333,0-39.04-5.013-54.08-15.253c-25.92-17.6-41.387-45.973-41.387-75.947c0-5.867-4.8-10.667-10.667-10.667s-10.667,4.8-10.667,10.667c0,37.12,18.987,72.107,50.667,93.653c18.453,12.48,40.747,18.88,66.133,18.88c2.987,0,13.547-0.107,26.027-2.347c5.76-1.067,9.707-6.613,8.64-12.373S346.329,358.187,340.569,359.253z" />
		</svg>
	)
}

