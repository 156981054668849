import React from 'react';

export default function Star({ height, width, fillColor }) {
    return (
        <svg
            height={height}
            width={width}
            viewBox="0 -1 25 25"
            fill={fillColor}
        >
            <path d="m12 24c-6.617 0-12-5.383-12-12 0-1.201.179-2.391.532-3.537.082-.263.362-.413.625-.331.264.082.412.361.331.625-.324 1.051-.488 2.142-.488 3.243 0 6.065 4.935 11 11 11s11-4.935 11-11-4.935-11-11-11c-1.101 0-2.192.164-3.243.488-.266.082-.544-.068-.625-.331s.067-.543.331-.625c1.146-.353 2.336-.532 3.537-.532 6.617 0 12 5.383 12 12s-5.383 12-12 12z" />
            <path d="m11.91 19c-.002 0-.004 0-.007 0-3.806-.053-6.903-3.194-6.903-7 0-3.86 3.14-7 7-7 3.806 0 6.947 3.097 7 6.903.004.276-.217.503-.493.507-.272-.037-.503-.217-.507-.493-.046-3.263-2.737-5.917-6-5.917-3.309 0-6 2.691-6 6 0 3.263 2.654 5.954 5.917 6 .276.004.497.231.493.507-.004.274-.227.493-.5.493z" />
            <path d="m4 7c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
            <path d="m16.5 19c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
            <path d="m12 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z" />
        </svg>

    )
}